import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Mission from "../../../assets/Images/AboutUs/Mission.png";
import Vision from "../../../assets/Images/AboutUs/Vision.png";
import About1 from "../../../assets/Images/AboutUs/About1.png";
import About2 from "../../../assets/Images/AboutUs/About2.png";
import { Link } from "react-router-dom";
export default class SimpleSlider extends Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="mainAboutUs">
            <div className="aboutUs">
              <div className="titleFont">
                <h1>About TRUCKMART</h1>
              </div>
            </div>
            <Row>
              <Col xl={6} lg={6} data-aos="fade-up">
                <div className="aboutUs">
                  <div className="aboutIconMainDivFloat">
                    <div className="aboutIconMainDiv">
                      <div className="aboutIconCircle">
                        <img
                          className="img-fluid mx-auto"
                          src={Mission}
                          alt="OurFeature"
                        />
                      </div>
                    </div>
                    <div className="aboutUsIconSideText">
                      <h6> Garage Repairs </h6>
                      <p>
                        At Truckmart, we provide expert repair and maintenance
                        services for all types of trucks. We pride ourselves on
                        ensuring that your trucks are road-ready, minimizing
                        downtime and maximizing efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="aboutIconMainDivFloat">
                    <div className="aboutIconMainDiv">
                      <div className="aboutIconCircle">
                        <img
                          className="img-fluid mx-auto"
                          src={Vision}
                          alt="OurFeature"
                        />
                      </div>
                    </div>
                    <div className="aboutUsIconSideText">
                      <h6> Truck Sales </h6>
                      <p>
                        Whether you're looking for new or used trucks, Truckmart
                        has you covered. We offer a wide range of trucks to suit
                        various needs and budgets. Each vehicle undergoes
                        thorough inspection to ensure quality and reliability,
                        so you can be confident in your purchase.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} data-aos="fade-up">
                <div className="aboutUs">
                  <div className="aboutIconMainDivFloat">
                    <div className="aboutIconMainDiv">
                      <div className="aboutIconCircle">
                        <img
                          className="img-fluid mx-auto"
                          src={About1}
                          alt="OurFeature"
                        />
                      </div>
                    </div>
                    <div className="aboutUsIconSideText">
                      <h6> Spare Part Sales </h6>
                      <p>
                        In addition to our repair and sales services, Truckmart
                        supplies a vast inventory of genuine and aftermarket
                        spare parts for trucks of all makes and models. From
                        engine components to body parts, we provide durable and
                        affordable solutions to keep your fleet running
                        smoothly.
                      </p>
                    </div>
                  </div>
                  <div className="aboutIconMainDivFloat">
                    <div className="aboutIconMainDiv">
                      <div className="aboutIconCircle">
                        <img
                          className="img-fluid mx-auto"
                          src={About2}
                          alt="OurFeature"
                        />
                      </div>
                    </div>
                    {/* <div className="aboutUsIconSideText">
                      <h6> Empowering Education </h6>
                      <p>
                        Our online college provides flexible, cutting-edge
                        education and training programs, helping students
                        achieve their academic and professional goals.
                      </p>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <div className="aboutUs">
              <div className="titleFont">
                <h1>SERVICE CENTERED</h1>
                <h5>
                  At Truckmart, we are committed to delivering top-notch service
                  and providing customers with reliable trucks and parts,
                  ensuring that their operations run smoothly. Let us be your
                  go-to source for all your truck-related needs.
                </h5>
              </div>
              <button className="homeBannerBtn ourSurvice">
                <Link to="/aboutus">About More</Link>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
