import React from "react";
import Progress from "./Progress";
import { Row, Col } from "react-bootstrap";
// import YearOfEx from "../../../assets/Images/YearOfExperience/YearOfEx.png";
import BlackThemeLogo from "../../../assets/Images/BrandLogo/BlackThemeLogo.png";

const YearOfExperience = () => (
  <>
    <div className="yearOfExperience">
      <div className="container">
        <Row className="align-items-center">
          <Col xs={12} sm={6}>
            <img
              src={BlackThemeLogo} // Replace with Truckmart's logo if available
              alt="Truckmart - Excellence in Truck Services"
              className="img-fluid mx-auto"
            />
          </Col>
          <Col xs={12} sm={6} className="homeYearExper">
            <div className="scrollbar">
              <div>
                <h3>Garage Repairs Expertise</h3>
                <Progress done="90" />{" "}
                {/* Adjust the progress percentage as needed */}
                <h3>Truck Sales Success</h3>
                <Progress done="75" />{" "}
                {/* Adjust the progress percentage as needed */}
                <h3>Spare Part Supply Chain</h3>
                <Progress done="85" />{" "}
                {/* Adjust the progress percentage as needed */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </>
);

export default YearOfExperience;
