import React from "react";
import "../../../../src/theme/css/HomePage.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import MainBannerImg from "../../../assets/Images/Banner/MainBannerImg.jpg";
import MainBannerImg1 from "../../../assets/Images/Banner/MainBannerImg1.jpg";
import MainBannerImg2 from "../../../assets/Images/Banner/MainBannerImg2.jpg";
import MainBannerImg3 from "../../../assets/Images/Banner/MainBannerImg3.jpg";
import MainBannerImg4 from "../../../assets/Images/Banner/MainBannerImg4.jpg";
import MainBannerImg5 from "../../../assets/Images/Banner/MainBannerImg5.jpg";

export default function Home() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1600, settings: { slidesToShow: 1 } },
      { breakpoint: 800, settings: { slidesToShow: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  const images = [
    MainBannerImg,
    MainBannerImg1,
    MainBannerImg2,
    MainBannerImg3,
    MainBannerImg4,
    MainBannerImg5,
  ];

  return (
    <div className="mainBannerDiv">
      <Slider {...settings} className="row">
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img
              className="img-fluid mx-auto"
              src={image}
              alt={`BannerImage${index + 1}`}
            />
            <div className="overlay"></div>
          </div>
        ))}
      </Slider>
      <div className="bannerContain">
        <h1>TRUCKMART AFRICA LIMITED</h1>
        <h5>
          Truckmart is your trusted partner in the automotive industry,
          specializing in comprehensive garage repair services, truck sales, and
          the supply of high-quality spare parts. We cater to the needs of fleet
          owners, logistics companies, and individual truck operators by
          ensuring that their vehicles remain in optimal condition and on the
          road.
        </h5>
        <div className="bannerBtn">
          <button className="ourSurvice">
            <Link to="/service">Our Service</Link>
          </button>
          <button className="ourContact">
            <Link to="/contact">Our Contact</Link>
          </button>
        </div>
      </div>
    </div>
  );
}
