import React from "react";
import { useParams } from "react-router-dom";

const TruckGallery = () => {
  const { truckName } = useParams();

  // Build image paths based on truck name dynamically
  const truckImages = [
    require(`../../../assets/Images/Gallery/${truckName}/image1.jpg`),
    require(`../../../assets/Images/Gallery/${truckName}/image2.jpg`),
    require(`../../../assets/Images/Gallery/${truckName}/image3.jpg`),
  ];

  return (
    <>
      <div className="otherPageBanner portfolioBanner">
        <div className="text-center title">
          <h1>Gallery</h1>
          <h1>{truckName} </h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {truckImages.map((image, index) => (
            <div className="col-md-4" key={index}>
              <img
                src={image}
                alt={`${truckName} ${index + 1}`}
                className="img-fluid"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TruckGallery;
