import React from "react";
import Slider from "react-slick";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function OurBestServices() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1900,
    autoplay: true,
    autoplaySpeed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="ourBestServicesBanner">
        <div className="container">
          <div className="ourBestServices text-center">
            <div className="titleFont">
              <h3>What We Do Best</h3>
              <h2>Our Top Services</h2>
            </div>
            <div className="ourBestServicesRow beffect">
              <Slider {...settings} className="row">
                <Col sm={4} xs={12}>
                  <Card data-aos="fade-up">
                    <Card.Body>
                      <Card.Title>Garage Repairs</Card.Title>
                      <Card.Text>
                        Truckmart offers high-quality garage repairs,
                        specializing in maintaining and repairing trucks to
                        ensure top performance and longevity.
                      </Card.Text>
                      <button className="homeBannerBtn ourSurvice">
                        <Link to="/faq">Read More</Link>
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={4} xs={12}>
                  <Card data-aos="fade-up">
                    <Card.Body>
                      <Card.Title>Truck Sales</Card.Title>
                      <Card.Text>
                        Looking for a reliable truck? Truckmart offers a wide
                        range of trucks for sale, from light-duty to heavy-duty,
                        with a focus on quality and affordability.
                      </Card.Text>
                      <button className="homeBannerBtn ourSurvice">
                        <Link to="/faq">Read More</Link>
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={4} xs={12}>
                  <Card data-aos="fade-up">
                    <Card.Body>
                      <Card.Title>Spare Parts</Card.Title>
                      <Card.Text>
                        Truckmart stocks a wide variety of truck spare parts,
                        ensuring that you have access to high-quality components
                        to keep your trucks running smoothly.
                      </Card.Text>
                      <button className="homeBannerBtn ourSurvice">
                        <Link to="/faq">Read More</Link>
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={4} xs={12}>
                  <Card data-aos="fade-up">
                    <Card.Body>
                      <Card.Title>Quick Assistance</Card.Title>
                      <Card.Text>
                        Truckmart takes pride in offering fast and reliable
                        customer support, ensuring that your queries and
                        concerns are addressed promptly.
                      </Card.Text>
                      <button className="homeBannerBtn ourSurvice">
                        <Link to="/faq">Read More</Link>
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
