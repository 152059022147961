import React, { Component } from "react";
import Slider from "react-slick";
import { Card, Col, Row } from "react-bootstrap";
import blog1 from "../../../assets/Images/Blog/blog1.png";
import blog2 from "../../../assets/Images/Blog/blog2.png";
import blog3 from "../../../assets/Images/Blog/blog3.png";
import blog4 from "../../../assets/Images/Blog/blog4.png";
import blog5 from "../../../assets/Images/Blog/blog5.png";
import AboutUsVideo from "../AboutUs/AboutUsVideo";
import { Link } from "react-router-dom";

export default class OurBlog extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        <div className="container">
          <div className="ourBlog">
            <div className="titleFont">
              <h1>Truckmart Blog</h1>
            </div>
            <div>
              <div className="yearOfExperience">
                <div className="container">
                  <Row className="align-items-center">
                    <Col xs={12} sm={6}>
                      <AboutUsVideo />
                    </Col>
                    <Col xs={12} sm={6} className="homeYearExper">
                      <div className="scrollbar">
                        <div>
                          <h3>
                            Your Reliable Partner for Truck Sales, Repairs, and
                            Spare Parts.
                          </h3>
                          <h3>Discover Our Latest Insights!</h3>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <br />
              <h2>Latest Blog Posts</h2>
              <div className="beffect">
                <Slider {...settings} className="row">
                  <Col xl={4}>
                    <div className="ourBlogCard" data-aos="fade-up">
                      <Card>
                        <div className="bimg">
                          <Card.Img
                            className="img-fluid mx-auto"
                            variant="top"
                            src={blog1}
                          />
                        </div>
                        <Card.Body>
                          <div className="blogCardBody">
                            <div className="d-inline ourBlogContain">
                              <span>22 August 2024</span>
                              <span className="d-inline ourBlogContainIcon">
                                <i
                                  className="fa fa-user"
                                  aria-hidden="true"
                                ></i>
                                By Admin
                              </span>
                            </div>
                            <hr />
                            <Card.Title>Truck Maintenance Tips</Card.Title>
                            <Card.Text>
                              Learn how to keep your trucks in top shape with
                              our expert maintenance tips for both fleet owners
                              and individual truck drivers.
                            </Card.Text>
                            <button className="homeBannerBtn ourSurvice">
                              <Link to="/portfolio">Read More</Link>
                            </button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>

                  <Col xl={4}>
                    <div className="ourBlogCard" data-aos="fade-up">
                      <Card>
                        <div className="bimg">
                          <Card.Img
                            className="img-fluid mx-auto"
                            variant="top"
                            src={blog2}
                          />
                        </div>
                        <Card.Body>
                          <div className="blogCardBody">
                            <div className="d-inline ourBlogContain">
                              <span>15 September 2024</span>
                              <span className="d-inline ourBlogContainIcon">
                                <i
                                  className="fa fa-user"
                                  aria-hidden="true"
                                ></i>
                                By Admin
                              </span>
                            </div>
                            <hr />
                            <Card.Title>Choosing the Right Truck</Card.Title>
                            <Card.Text>
                              Tips on selecting the best trucks for your
                              business, including factors like fuel efficiency,
                              durability, and resale value.
                            </Card.Text>
                            <button className="homeBannerBtn ourSurvice">
                              <Link to="/portfolio">Read More</Link>
                            </button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>

                  <Col xl={4}>
                    <div className="ourBlogCard" data-aos="fade-up">
                      <Card>
                        <div className="bimg">
                          <Card.Img
                            className="img-fluid mx-auto"
                            variant="top"
                            src={blog3}
                          />
                        </div>
                        <Card.Body>
                          <div className="blogCardBody">
                            <div className="d-inline ourBlogContain">
                              <span>1 October 2024</span>
                              <span className="d-inline ourBlogContainIcon">
                                <i
                                  className="fa fa-user"
                                  aria-hidden="true"
                                ></i>
                                By Admin
                              </span>
                            </div>
                            <hr />
                            <Card.Title>Spare Parts Essentials</Card.Title>
                            <Card.Text>
                              A guide to the most essential truck spare parts
                              you should always have on hand for quick repairs
                              and minimal downtime.
                            </Card.Text>
                            <button className="homeBannerBtn ourSurvice">
                              <Link to="/portfolio">Read More</Link>
                            </button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
