import Ashok_Leyland_1618 from "../../../assets/Images/TrucksTitles/Ashok_Leyland_1618.jpg";
import Ashok_Leyland_2518il_Haulage from "../../../assets/Images/TrucksTitles/Ashok_Leyland_2518il_Haulage.jpg";
import Ashok_Leyland_2518il_Tipper from "../../../assets/Images/TrucksTitles/Ashok_Leyland_2518il_Tipper.jpg";
import Ashok_Leyland_9016_10t from "../../../assets/Images/TrucksTitles/Ashok_Leyland_9016_10t.jpg";
import Ashok_Leyland_Ecomet_1012 from "../../../assets/Images/TrucksTitles/Ashok_Leyland_Ecomet_1012.jpg";
import Ashok_Leyland_Partner from "../../../assets/Images/TrucksTitles/Ashok_Leyland_Partner.jpg";
import Ashok_Leyland_Phoenix from "../../../assets/Images/TrucksTitles/Ashok_Leyland_Phoenix.jpg";
import Boss_1218 from "../../../assets/Images/TrucksTitles/Boss_1218.jpg";
import Boss_1218_12t from "../../../assets/Images/TrucksTitles/Boss_1218_12t.jpg";
import Haulage_Bitumen_2518il_Sprayer from "../../../assets/Images/TrucksTitles/Haulage_Bitumen_2518il_Sprayer.jpg";
import Partner_Truck from "../../../assets/Images/TrucksTitles/Partner_Truck.jpg";
import Test_Pump_Unit_9016 from "../../../assets/Images/TrucksTitles/Test_Pump_Unit_9016.jpg";
import U_Tipper_2518 from "../../../assets/Images/TrucksTitles/U_Tipper_2518.jpg";

export const BlogData = [
  {
    id: 1,
    image: Ashok_Leyland_1618,
    like: "596 likes",
    title: "Ashok Leyland 1618",
    contain: `
            This is an upgraded model that has been added to our selection.

            The Ashok Leyland 1618 comes with a 24ft loading platform, 5,334mm wheelbase, and a sleeper cabin.

            It is very comfortable when it comes to long distance and off-road terrains.

            It has also been used for coach building successfully.

            - 16t GVW
            - 180hp
            - 24ft loading platform
            - 5,334mm wheelbase
            - 6 speed
        `,
  },
  {
    id: 2,
    image: Ashok_Leyland_2518il_Haulage,
    like: "409 likes",
    title: "Ashok Leyland 2518il Haulage",
    contain: `
            Ashok Leyland 2518il Haulage truck has been supplied to the market for over thirteen years now and it is very convenient for long distance deliveries. 
            
            This  truck comes fitted with a 24ft loading platform, sleeper cabin, has a 180hp, turbo charged, fuel injected and 5,759cc engine.

            The 2518il Haulage truck is designed in a way it is able to handle tougher terrains and tough applications like sand harvesting and bulky goods transportation. 
            
            It is super very convenient for long distance deliveries and it has extra comfortable seats.

            
            - 180hp
            - 24ft loading platform
            - 5,759cc engine
            
        `,
  },
  {
    id: 3,
    image: Ashok_Leyland_2518il_Tipper,
    like: "788 likes",
    title: "Ashok Leyland 2518il Tipper",
    contain: `
        The Ashok Leyland 2518il Tipper has been supplied to the Kenyan market for over 15 years and is one of the most trusted tipper trucks by customers. 
        
        Over the years the 2518il Tipper has been tested and proven on more load capacity (16-19T), more earnings, lower maintenance cost, 
        
        easy handling, better pick up power and shorter turnaround time.


        -25T GVW
        -180hp
        -Turbo charged, fuel injected
        -Non computerized model
        - 6 cylinder
        - 6 speed
        - 8,000km service intervals.

        `,
  },

  {
    id: 4,
    image: Ashok_Leyland_9016_10t,
    like: "788 likes",
    title: "Ashok Leyland 9016 10t",
    contain: `
        Ashok Leyland 9016 is one of the most trusted trucks by hardware businesses in the 10t payload category. 
        
        This truck has been tested and proven on durability, reliability , fuel efficiency, more load and easy handling/maintenance. 
        
        It comes with a 19ft loading platform, 5,759cc, 160hp, 6 cylinder, turbo charged and fuel injected engine. 
        
        This truck comfortably gives 5km/l. A brand new unit is currently going for 5m with a corrugated body.
        

        - 5,759cc
        - 160hp
        - 19ft loading platform
        - Turbo charged and fuel injected engine
        - 6 cylinder
        - 5km/l
        

        `,
  },
  {
    id: 5,
    image: Ashok_Leyland_Ecomet_1012,
    like: "788 likes",
    title: "Ashok Leyland Ecomet 1012",
    contain: `
        The Ashok Leyland Ecomet 1012 stands out as a robust truck with a gross vehicle weight of 10,900 kg. 
        
        It is equipped with a powerful 3,839 cc H Series 4-cylinder CRS engine utilizing EGR technology, generating 130 HP and an impressive maximum torque of 450 Nm. 
        
        The engine is seamlessly paired with a 6-speed manual (6F+1R) gearbox, ensuring superior mileage of up to 7km/l

        Designed for versatility, the Ecomet 1012 operates on a 4x2 axle configuration, with a 3970mm wheelbase and a 17ft loading platform.  
        
        The inclusion of air brakes ensures top-notch safety, while the shackle-ended leaf springs suspension in the front provides a comfortable and smooth ride quality. 
        
        The Ashok Leyland Ecomet 1012 proves to be a reliable and adaptable choice for a wide range of transportation needs.


        - 3,839 cc
        - 130 HP
        - 17ft loading platform
        - Turbo charged and fuel injected engine
        - 4-cylinder
        - 7km/l
        

        `,
  },
  {
    id: 6,
    image: Ashok_Leyland_Partner,
    like: "788 likes",
    title: "Ashok Leyland Partner",
    contain: `
        This canter sized truck is very popular in the 5 -7t category. 
        
        Partner can fit in perfectly in a wide range of applications and it handles both long distance and short distance comfortably. 
        
        Partner's GVW is 7.2t, has 14ft loading platform, 2,953cc, 4 cylinder, Turbo charged and fuel injected engine. 
        
        A new unit complete with corrugated body available


        - 7.2t category
        - 130 HP
        - 14ft loading platform
        - Turbo charged and fuel injected engine
        - 4-cylinder
        - 2,953cc
        

        `,
  },
  {
    id: 7,
    image: Ashok_Leyland_Phoenix,
    like: "788 likes",
    title: "Ashok Leyland Phoenix",
    contain: `
        This is a new addition in the mini trucks category

        Phoenix is big on comfort, big on performance and big on savings.

        It has a 1.5l 3 cylinder diesel BS4, engine, 80hp maximum power, 3,500kg GVW and a 10ft loading platform.



        - 7.2t category
        - 130 HP
        - 14ft loading platform
        - Turbo charged and fuel injected engine
        - 4-cylinder
        - 2,953cc
        

        `,
  },
  {
    id: 8,
    image: Boss_1218,
    like: "788 likes",
    title: "Ashok Boss 1218 - 12t",
    contain: `
        Boss 1218 is one of our  newest addition but it has taken the market by storm. 
        
        This truck is a perfect fit for anyone looking for more power, more load, shorter turnaround time, better pick up power and more earnings in the 12t payload category.

        Boss 1218 has a 22ft loading platforms, 180hp, 5,759cc, 6 cylinder, turbo charged and fuel injected engine.


        - 22ft loading platforms
        - 180hp
        - 14ft loading platform
        - Turbo charged and fuel injected engine.
        - 6 cylinder
        - 5,759cc      

        `,
  },
  {
    id: 9,
    image: Haulage_Bitumen_2518il_Sprayer,
    like: "788 likes",
    title: "Haulage Bitumen 2518il Sprayer",
    contain: `
         

        `,
  },

  {
    id: 10,
    image: Partner_Truck,
    like: "760 likes",
    title: "Partner Truck",
    contain: `
         This canter sized truck is very popular in the 5 -7t category. 
         
         Partner can fit in perfectly in a wide range of applications and it handles both long distance and short distance comfortably. 
         
         Partner's GVW is 7.2t, has 14ft loading platform, 2,953cc, 4 cylinder, Turbo charged and fuel injected engine.

        - 7.2t GVW
        - 14ft loading platform
        - 2,953cc
        - 4 cylinder
        - Turbo charged and fuel injected engine.
        `,
  },
];
export default BlogData;
