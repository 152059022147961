import React, { useEffect } from "react";
import "../../../../src/theme/css/HomePage.css";
import { Row, Col } from "react-bootstrap";
import Feature1 from "../../../assets/Images/OurFeatures/Feature1.png";
import Feature2 from "../../../assets/Images/OurFeatures/Feature2.png";
import Feature3 from "../../../assets/Images/OurFeatures/Feature3.png";
import OurFeature1 from "../../../assets/Images/OurFeatures/OurFeature1.png";
import OurFeature2 from "../../../assets/Images/OurFeatures/OurFeature2.png";
import OurFeature3 from "../../../assets/Images/OurFeatures/OurFeature3.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

export default function OurFeature() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="ourFeature">
          <div className="titleFont text-center" data-aos="zoom-in-down">
            <h3>Our Operations</h3>
            <h2>What we do</h2>
          </div>
          <div className="mainFeaturesCard">
            <Row>
              <Col xs={12} sm={4}>
                <div className="featuresCard" data-aos="zoom-in">
                  <div className="featuresCardCircle">
                    <img
                      className="img-fluid mx-auto"
                      src={Feature1}
                      alt="OurFeature"
                    />
                  </div>
                  <div className="featuresCardContain">
                    <h3>We bring our services to you</h3>
                    <img
                      className="img-fluid mx-auto"
                      src={OurFeature1}
                      alt="OurFeature"
                    />
                    <details className="details">
                      <summary className="summary">
                        <span id="open">Read More</span>
                        <span id="close">Less</span>
                      </summary>

                      <h6 className="maskbtnOpenText">
                        Truckmart offers on the ground services and uplift
                        whenever called on to.
                      </h6>
                    </details>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="featuresCard" data-aos="zoom-in">
                  <div className="featuresCardCircle">
                    <img
                      className="img-fluid mx-auto"
                      src={Feature2}
                      alt="OurFeature"
                    />
                  </div>
                  <div className="featuresCardContain">
                    <h3>Pocket Friendly prices</h3>

                    <img
                      className="img-fluid mx-auto"
                      src={OurFeature2}
                      alt="OurFeature"
                    />

                    <details className="details">
                      <summary className="summary">
                        <span id="open">Read More</span>
                        <span id="close">Less</span>
                      </summary>

                      <h6 className="maskbtnOpenText">
                        Our prices on imported parts are incomparable in the
                        market today and Kenya at large.
                      </h6>
                    </details>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="featuresCard" data-aos="zoom-in">
                  <div className="featuresCardCircle">
                    <img
                      className="img-fluid mx-auto"
                      src={Feature3}
                      alt="OurFeature"
                    />
                  </div>
                  <div className="featuresCardContain">
                    <h3>Discount of Trucks and Busses</h3>
                    <img
                      className="img-fluid mx-auto"
                      src={OurFeature3}
                      alt="OurFeature"
                    />
                    <details className="details">
                      <summary className="summary">
                        <span id="open">Read More</span>
                        <span id="close">Less</span>
                      </summary>

                      <h6 className="maskbtnOpenText">
                        Highest discounts on the market today. Get the best out
                        of your money today.
                      </h6>
                    </details>
                  </div>
                </div>
              </Col>
            </Row>
            <button className="homeBannerBtn ourSurvice">
              <Link to="/service">All Services</Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
