import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AboutMenuBlog from "../../../assets/Images/AboutUs/AboutMenuBlog.png"; // Change image to relevant Truckmart asset
import { Row, Col } from "react-bootstrap";
import "../../../theme/css/AboutUs.css";
import AboutUsVideo from "../AboutUs/AboutUsVideo";
import Aos from "aos";
import "aos/dist/aos.css";

export default function AboutUsMenu() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div className="otherPageBanner contactUsBanner">
        <div className="text-center title">
          <h2>ABOUT TRUCKMART</h2>
          <h6>Trusted Garage, Truck Sales, and Spare Parts Services</h6>
        </div>
      </div>
      <div className="container">
        <div className="aboutMenu beffect">
          <Row>
            <Col xl={5} lg={5} md={5} sm={12}>
              <div className="bimg">
                <img
                  className="img-fluid mx-auto"
                  src={AboutMenuBlog}
                  alt="AboutMenu"
                  data-aos="flip-left"
                />
              </div>
            </Col>
            <Col xl={7} lg={7} md={7} sm={12} className="colForResponsive">
              <div className="titleFont">
                <h3>ABOUT OUR BUSINESS</h3>
                <h2>
                  Your Partner in Reliable Truck Services, Sales, and Repairs
                </h2>
              </div>
              <p>
                At Truckmart, we specialize in providing top-tier truck repair
                services, quality spare parts, and sales of durable trucks. Our
                team is committed to ensuring that your business runs smoothly
                by offering comprehensive solutions for all your trucking needs.
              </p>
              <button className="ourSurvice">
                <Link to="/faq">Explore Our Services</Link>
              </button>
            </Col>
          </Row>
        </div>

        <div className="aboutusMenuCounting">
          <Row>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>10K+</h2>
              <h6>Trucks Repaired</h6>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>500+</h2>
              <h6>Trucks Sold</h6>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>1M+</h2>
              <h6>Spare Parts Available</h6>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>50+</h2>
              <h6>Awards for Service Excellence</h6>
            </Col>
          </Row>
        </div>

        <AboutUsVideo />
      </div>
    </>
  );
}
