import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import FinancingBanks from "../../../assets/Images/Planing/FinancingBanks.png";
import Ashok_v_Truckmart from "../../../assets/Images/Planing/Ashok_v_Truckmart.png";
import Aos from "aos";
import "aos/dist/aos.css";
// import { Link } from "react-router-dom";
export default function OurPricing() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="ourPricing">
          <div className="titleFont">
            <h1>Our Pricing</h1>

            <h3>Pocket Friendly on all Aspects</h3>
          </div>
          <div className="ourPricingCard text-center">
            <Row>
              <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                <Card data-aos="fade-up">
                  <Card data-aos="fade-up">
                    <Card.Body>
                      <Card.Title>
                        <div className="featuresCard" data-aos="flip-left">
                          <h5>Financing</h5>
                        </div>
                      </Card.Title>

                      <br />
                      <img
                        className="img-fluid mx-auto"
                        src={FinancingBanks}
                        alt="OurFeature"
                      />
                    </Card.Body>
                  </Card>
                </Card>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Card data-aos="fade-up">
                  <Card data-aos="fade-up">
                    <Card.Body>
                      <Card.Title>
                        <div className="featuresCard" data-aos="flip-left">
                          <h5>Partnership X Ashokleyland</h5>
                        </div>
                      </Card.Title>

                      <br />
                      <img
                        className="img-fluid mx-auto"
                        src={Ashok_v_Truckmart}
                        alt="OurFeature"
                      />
                    </Card.Body>
                  </Card>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
